
























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import ProcedureStatusGroup from "./ProcedureStatusGroup.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import PatientCard from "./ProcedureCard.vue";
import FilterByPatient from "./FilterByPatient.vue";

import values from "lodash/values";
import { DropResult, applyDrag } from "./dragDropUtils";
import { mapActions, mapGetters } from "vuex";
import { ProcedureStatus } from "@/models/Patient";
import { getAPI } from "@/api/axios-base";
import PpsSales from "../PPS/PpsSales.vue";

export default Vue.extend({
  name: "dashboard-procedure-status-list",
  components: {
    ProcedureStatusGroup,
    PatientCard,
    Container,
    Draggable,
    FilterByPatient,
    PpsSales,
  },
  data() {
    return {
      tab: null,
      cards: [],
      loading: false,
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
    };
  },
  computed: {
    ...mapGetters("crmDashboardModule", ["getPatientsByStatus"]),
    ...mapGetters(["getviewPps"]),
    style(): any {
      const style = {
        height: `${
          this.$vuetify.breakpoint.height -
          64 * 3 -
          (this.$vuetify.breakpoint.lgAndUp ? 25 : 15)
        }px !important`,
      };

      return style;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListPatientsFilter",
      "actUpdatePatientStatus",
    ]),
    ...mapActions("crmDashboardModule", [
      "actProcedures",
      "actUpdateProcedureStatus",
      "actFilterDashboardProcedure",
    ]),
    onCardDrop(columnId: ProcedureStatus, dropResult: DropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const cards: any = values(Object.assign({}, this.cards));
        const column = this.cards.filter(
          (card: any) => card.id === columnId
        )[0];
        const columnIndex = cards.indexOf(column);
        const newColumn: any = Object.assign({}, column);

        newColumn.children = applyDrag(newColumn.children, dropResult);
        cards.splice(columnIndex, 1, newColumn);
        this.cards = cards;

        // Update only if is a valid movement
        if (dropResult.addedIndex != null && dropResult.removedIndex == null) {
          this.loading = true;
          this.updateProcedureStatus(dropResult.payload.id, columnId)
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
    // Get the data from childs
    getCardPayload(columnId: number | string) {
      return (index: number) => {
        return (this as any).cards.filter((p: any) => p.id === columnId)[0]
          .children[index];
      };
    },
    dragStart() {
      // console.log("drag started");
    },
    log(...params: any) {
      // console.log(...params);
    },
    async updateProcedureStatus(
      id: string,
      status: ProcedureStatus
    ): Promise<any> {
      this.loading = false;
      this.actUpdateProcedureStatus({
        id: id,
        status: status,
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async init() {
      this.loading = true;
      const body = {};
      this.actFilterDashboardProcedure(body).then((procedures: any) => {
        this.loading = false;
        this.cards = procedures;
      });
      document
        .getElementsByTagName("html")[0]
        .setAttribute("style", "overflow-y: hidden;");
    },
  },
  beforeDestroy() {
    document.getElementsByTagName("html")[0].setAttribute("style", "");
  },
});
