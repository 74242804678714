var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-autocomplete',{staticClass:"mx-1",staticStyle:{"max-width":"300px"},attrs:{"label":'Filter by ' + _vm.getpatientN,"items":_vm.patients,"item-text":"fullname","item-value":"uuid","prepend-inner-icon":"mdi-account-search","clearable":"","hide-details":"","outlined":"","dense":""},on:{"change":_vm.getSummary},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}})],1)]]}}]),model:{value:(_vm.filterRequest.patientUuid),callback:function ($$v) {_vm.$set(_vm.filterRequest, "patientUuid", $$v)},expression:"filterRequest.patientUuid"}}),_c('v-autocomplete',{staticClass:"mx-1",staticStyle:{"max-width":"300px"},attrs:{"label":"Filter by Employees","items":_vm.workers,"item-text":"fullname","item-value":"uuid","prepend-inner-icon":"mdi-account-search","clearable":"","hide-details":"","outlined":"","dense":""},on:{"change":_vm.getSummary},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.rol)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.phone)}})],1)]]}}]),model:{value:(_vm.filterRequest.employeeUuid),callback:function ($$v) {_vm.$set(_vm.filterRequest, "employeeUuid", $$v)},expression:"filterRequest.employeeUuid"}}),_c('v-layout',{staticClass:"ma-4"},[_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3"},[_c('ma-date-picker',{attrs:{"datepickerProps":{
              'no-title': true,
            },"label":"From"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{staticClass:"ml-2 mt-3"},[_c('ma-date-picker',{attrs:{"datepickerProps":{
              'no-title': true,
            },"label":"To"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1),(_vm.validClean)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","elevation":"0","small":"","rounded":"","depressed":"","fab":""},on:{"click":function($event){return _vm.resetFilter()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,false,3598858563)},[_c('span',[_vm._v("Clear Dates")])])]:_vm._e()],2),_vm._v(" "+_vm._s(/* Interval indicator */)+" "),_c('v-layout',{staticClass:"pa-4",attrs:{"row":""}},[_c('v-layout',{attrs:{"column":""}},[_c('div',{staticClass:"mr-2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.typeDate.replace("-", " "))+" ")]),_c('v-layout',{staticClass:"text-body-1 text--secondary",attrs:{"column":""}},[_c('div',[_vm._v(" From: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDateShow(_vm.filterRequest.dateRange.date1)))])]),_c('div',[_vm._v(" To: "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDateShow(_vm.filterRequest.dateRange.date2)))])])])],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.summarys,"server-items-length":_vm.totalSummarys,"options":_vm.options,"item-key":"index","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToPatient},scopedSlots:_vm._u([{key:"item.coordinator",fn:function(ref){
            var item = ref.item;
return [(item.coordinator)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName(item.coordinator))}},on),[_vm._v(" "+_vm._s(_vm.getName(item.coordinator)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.coordinator))])])]:_vm._e()]}},{key:"item.createAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.surgicaldate ? _vm.getDOS(item.surgicaldate) : "-")+" ")]}},{key:"item.notes",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.notes ? item.notes : "-")+" ")]}},{key:"item.amountdue",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amountdue))+" ")]}},{key:"item.patient",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.patient))])]}},{key:"item.procedures",fn:function(ref){
            var item = ref.item;
return [(item.procedures)?[_c('div',{staticClass:"procedures"},_vm._l((item.procedures),function(its){return _c('v-btn',{key:its.id,staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":its.color},on:{"click":function($event){return _vm.goToPatientprocedure(item, its)}}},[_vm._v(" "+_vm._s(_vm.getNameProcedure(its)))])}),1)]:[_vm._v("-")]]}},{key:"item.labs",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.labsCkeck.a1c)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('a1c'))}},[_vm._v(" a1c")])]:_vm._e(),(item.labsCkeck.chem)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('chem'))}},[_vm._v(" Chem")])]:_vm._e(),(item.labsCkeck.ekg)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('ekg'))}},[_vm._v(" ekg")])]:_vm._e(),(item.labsCkeck.hcg)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('hcg'))}},[_vm._v(" hcg")])]:_vm._e(),(item.labsCkeck.hiv)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('hiv'))}},[_vm._v(" hiv")])]:_vm._e(),(item.labsCkeck.man_us)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('man'))}},[_vm._v(" MAM/US")])]:_vm._e(),(item.labsCkeck.mc)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('mc'))}},[_vm._v(" mc")])]:_vm._e(),(item.labsCkeck.pt)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('pt'))}},[_vm._v(" pt")])]:_vm._e(),(item.labsCkeck.pt_ptt_inr)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('pt_ptt_inr'))}},[_vm._v(" PT/PTT/INR")])]:_vm._e(),(item.labsCkeck.ua)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('ua'))}},[_vm._v(" ua")])]:_vm._e()],2)]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.procedures)?[_c('div',{staticClass:"procedures"},_vm._l((item.procedures),function(its){return _c('div',{key:its.id},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btn_name",attrs:{"rounded":"","small":"","color":its.color},on:{"click":function($event){return _vm.goToPatientprocedure(item, its)}}},on),[_vm._v(" "+_vm._s(its.dasboardStatus))])]}}],null,true)},[(its.surgery)?[_c('span',[_vm._v(_vm._s("Procedure: " + its.surgery.name))])]:_vm._e(),(its.treatment)?[_c('span',[_vm._v(_vm._s("Procedure: " + its.treatment.name))])]:_vm._e()],2)],1)}),0)]:[_vm._v(" - ")]]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }